import DrynessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/dryness/DrynessTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinDryness/Dryness/DrynessTemplate',
  component: DrynessTemplate
};

const createStory = props => () => ({
  components: { DrynessTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><dryness-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  skinDryness: AMOUNT.NO
});

export const WithConsiderableDryness = createStory({
  skinDryness: AMOUNT.CONSIDERABLY,
  flakingSkin: 'yes',
  considerableDryness: true
});
